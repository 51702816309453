import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { inputsCustomizations } from './customizations/inputs.js';
import { dataDisplayCustomizations } from './customizations/dataDisplay.js';
import { feedbackCustomizations } from './customizations/feedback.js';
import { navigationCustomizations } from './customizations/navigation.js';
import { surfacesCustomizations } from './customizations/surfaces.js';
import { colorSchemes, typography, shadows, shape } from './themePrimitives.js';

function AppTheme({ children, disableCustomTheme, themeComponents }) {
  const theme = React.useMemo(() => {
    return disableCustomTheme
      ? {}
      : createTheme({
          // For more details about CSS variables configuration, see https://mui.com/material-ui/customization/css-theme-variables/configuration/
          cssVariables: {
            colorSchemeSelector: 'data-mui-color-scheme',
            cssVarPrefix: 'template',
          },
          palette: {
            mode: 'light',
          }, 
          typography,
          shadows,
          shape,
          components: {
            // ...inputsCustomizations,
            // ...dataDisplayCustomizations,
            // ...feedbackCustomizations,
            // ...navigationCustomizations,
            // ...surfacesCustomizations,
            ...themeComponents,
          },
        });
  }, [disableCustomTheme, themeComponents]);
  if (disableCustomTheme) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      {children}
    </ThemeProvider>
  );
}

AppTheme.propTypes = {
  children: PropTypes.node,
  /**
   * This is for the docs site. You can ignore it or remove it.
   */
  disableCustomTheme: PropTypes.bool,
  themeComponents: PropTypes.object,
};

export default AppTheme;
